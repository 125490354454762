/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */


var utag_data = function(a,b,c,d){
a='//tags.tiqcdn.com/utag/random/penguin-yr/prod/utag.js';
b=document;c='script';d=b.createElement(c);d.src=a;d.type='text/java'+c;d.async=true;
a=b.getElementsByTagName(c)[0];a.parentNode.insertBefore(d,a);
}();


//--------------------------------------------------------------------
// SUBMIT NEWSLETTER
//--------------------------------------------------------------------
var isValidEmailAddress = function (emailAddress) {
    var pattern = /^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
    return pattern.test(emailAddress);
};

var _PRH_submitNewsletter = function(form_id, utag_msg){
  
  var form          = jQuery(form_id);
  var email         = form.find('[name="email"]').val();
  var formMessage   = form.find('.signup-form-message');
  console.log('newsletter');


  if(!isValidEmailAddress(email)) {
      formMessage.text('Please check your e-mail is correct');
      return;
  }

  form.removeClass('error');

  // Ajax submition
  jQuery.ajax({
        url: "https://api.penguinrandomhouse.com/rest/v1/json/subscription/programsubscribejson/set",
        dataType: 'jsonp',
        data: {Email: email, Subs: '104,10401,1'}
  })
  .done(function(data) {

        if(data && data.StatusCode === 0) {
              
              if (utag.link){ // Basic Tealium Code
                utag.link({
                  'event_type':"newsletter_signup",
                  'newsletter_name': utag_msg
                });
              }
              
              jQuery(form).replaceWith('<p class="thanks-newsletter">Thank you for signing up!</p>');
              return;
        }

        form.addClass('error');
        formMessage.text(data.Message);
  })
  .fail(function() { // Fail
         formMessage.text('An error occurred, please try again later.');
    })
      
    .always(function() { // Always
       form.prop("disabled", false);
  });

  return false;
    
};


//--------------------------------------------------------------------


var setTilesSize = function(){
  var tile_width = jQuery('.big-tile').first().outerWidth(true);
  jQuery('.small-tile').each(function(){ 
    jQuery(this).css('height', tile_width/2); 
  });

  jQuery('.big-tile, .tile-wrapper').each(function(){
    jQuery(this).css('height', tile_width);  
  });

  jQuery('.main').fadeIn();
  jQuery('#section-apps').fadeIn();
  jQuery('#social-feeds').fadeIn();


  // jQuery('.tile-quote').each(function(){
  //   var height_tile  = ( parseInt( jQuery('.big-tile').first().innerHeight() ) / 2 ) - 40;
  //   var height_elem  = parseInt( jQuery(this).height() );
  //   var padd_elem = (height_tile - height_elem) / 2;
    
  //   jQuery(this).css('padding-top', padd_elem+'px');
  //   alert(jQuery().height());
  // });


};



  // Same height for history containers
  //--------------------------------------------------------------------
  var HistorySectionsSameHeight = function(){ 
    var items_container = jQuery('.history-slides');
    var item_heightest  = 0;
    var widthSum        = 0;
    var elem            = items_container.find('.history_milestone');

    elem.each(function(){
      console.log(jQuery(this).outerHeight());
      if ( jQuery(this).outerHeight() > item_heightest){
        item_heightest  = parseInt(jQuery(this).outerHeight() );
      }
      
      // console.log(this.height + ' => ' +item_heightest);
    });


    jQuery('.history-slides').find('.history_milestone').css('height',item_heightest+'px');

  };



 var alignPDPElemTop = function(){

    if (jQuery(window).width() >= 768){
      var resizeId;
      clearTimeout(resizeId);
      resizeId = setTimeout(function(){
          var from_top = 1000000000000000;
          jQuery('.pdp_cover').each(function(){
            if ( (jQuery(this).offset().top < from_top) && (jQuery(this).offset().top > 0) ){
              from_top = jQuery(this).offset().top;
            }
            
          });
          
         
          jQuery('.pdp_nav').css('top',  (from_top - jQuery('.navbar-header').outerHeight()) +'px');

        
          var slide_h = jQuery('#pdp_left .pdp_slide').outerHeight();

          jQuery('#pdp_right .hidden-xs .pdp_slide').each(function(){
            
            var cover_height = jQuery('.pdp_cover').first().outerHeight() - 200;
        
             jQuery(this).find('.pdp_title').css('padding-top', (from_top - jQuery('.navbar-header').outerHeight()) +'px');
      
          });



      }, 500);

      var resizeId;
        jQuery(window).load(function(){

            clearTimeout(resizeId);
            resizeId = setTimeout(function(){
              
              var from_top = 1000000000000000;
              jQuery('.pdp_cover').each(function(){
                if ( (jQuery(this).offset().top < from_top) && (jQuery(this).offset().top > 0) ){
                  from_top = jQuery(this).offset().top;
                }
                
              });
              
              jQuery('.pdp_nav').css('top',  (from_top - jQuery('.navbar-header').outerHeight()) +'px');


              var slide_h = jQuery('#pdp_left .pdp_slide').outerHeight();

              jQuery('#pdp_right .hidden-xs .pdp_slide').each(function(){
            
                jQuery(this).find('.pdp_title').css('padding-top', (from_top - jQuery('.navbar-header').outerHeight()) +'px');

              
              });

            }, 500);

        });


    }


 }


//  FUNCTIONS FOR BOOK CATEGORIES 
//========================================================================
  // Same height for book containers
  //--------------------------------------------------------------------
  var BooksSameHeight = function(){ 
    var items_container = jQuery('#all-books');
    var item_heightest  = 100000;
    var item_widthest  = 100000;

    var widthSum        = 0;
    var imgs            = items_container.find('li img');
    var item_width, item_height;
    var widthOfHeightest;

    imgs.each(function(){
  
      // Getting widthest
      if (this.width < item_widthest){
        // item_heightest  = parseInt(this.height);
        widthOfHeightest= parseInt(this.width);
      }

      // Getting heighest
      if (this.height < item_heightest){
        item_heightest  = parseInt(this.height);
        // widthOfHeightest= parseInt(this.width);
      }
      
      console.log(this.height + ' => ' +item_heightest);
    });

    item_width  = parseInt( jQuery('#grid').find('li').width() ) + 20;
    item_height = (item_width * item_heightest) /  widthOfHeightest ;

    jQuery('#grid').find('li').css('height',item_height+'px');
    jQuery('#grid').find('li').css('overflow','hidden');

  };


function getViewportH() {
    var client = window.document.documentElement['clientHeight'],
      inner = window['innerHeight'];
    
    if( client < inner ){
      return inner;
    }
    else{
      return client;
    }

}

  function scrollY() {
    return window.pageYOffset || window.document.documentElement.scrollTop;
  }


function getOffset( el ) {
    var offsetTop = 0, offsetLeft = 0;
    do {
      if ( !isNaN( el.offsetTop ) ) {
        offsetTop += el.offsetTop;
      }
      if ( !isNaN( el.offsetLeft ) ) {
        offsetLeft += el.offsetLeft;
      }
    } while( el = el.offsetParent )

    return {
      top : offsetTop,
      left : offsetLeft
    }
  }
function inViewport( el, h ) {
    var elH = el.offsetHeight,
      scrolled = scrollY(),
      viewed = scrolled + getViewportH(),
      elTop = getOffset(el).top,
      elBottom = elTop + elH,
      // if 0, the element is considered in the viewport as soon as it enters.
      // if 1, the element is considered in the viewport only when it's fully inside
      // value in percentage (1 >= h >= 0)
      h = h || 0;

    return (elTop + elH * h) <= viewed && (elBottom - elH * h) >= scrolled;
  }



  // Categories filter
  //--------------------------------------------------------------------
var loadBooksGrid = function(){
  

  // var anim = new AnimOnScroll( document.getElementById( 'grid' ), {
  //       minDuration : 0.4,
  //       maxDuration : 0.7,
  //       viewportFactor : 0
  // });
  
  // anim.items.forEach( function( el, i ) {
  //   if( inViewport( el ) ) {
  //     classie.add( el, 'animate' );
  //   }
  // } );

  BooksSameHeight();
};


var reAssignColumnsGridPrintables = function(elem){
    
    var multip_li = 0;
    var num_row  = 0;
    var viewport_width      = jQuery(window).width();
    
    console.log('view' + viewport_width);
   
    if (viewport_width <= 767){
      num_row = 1;
    }
    else if (viewport_width < 769){
      num_row = 2;
    }
    else{
      num_row = 4;
    }
    
    var far_top = 0;
    
    elem.each(function(){
      jQuery(this).css('top', 0);
    });

    elem.each( function(){
      var mod_i = (multip_li % num_row);
      var multip_l = jQuery('.multip'+mod_i).length;
      

      if ( multip_l > 0 ){
        
        
        var top_elem = jQuery('.multip'+mod_i).last().css('top');
        if (top_elem=='auto'){
          top_elem = 0;
        }

        // console.log('el elemento:' + top_elem);

        var top_item =  parseInt(top_elem) + jQuery('.multip'+mod_i).last().outerHeight(true);
        

        jQuery(this).css('left', jQuery('.multip'+mod_i).last().offset().left );
        jQuery(this).css('top', top_item );
        jQuery(this).css('position','absolute');

        if ((jQuery(this).offset().top + jQuery(this).outerHeight(true)) > far_top ){
          far_top =   jQuery(this).offset().top  - jQuery('.page-header').outerHeight(true) ;
        }

        
      }
        

      jQuery(this).addClass('multip'+mod_i);
      multip_li++;


    });  


    jQuery('.printable-collection').css('height',far_top+'px');

};


var reAssignColumnsGrid = function(elem){
    
    var multip_li = 0;
    var num_row  = 0;
    var viewport_width      = jQuery(window).width();
          
    if (viewport_width < 768){
      num_row = 2;
    }
    else if(viewport_width < 992 ){
      num_row = 4;
    }
    else{
      num_row = 6;
    }

    elem.each( function(){
      var mod_i = (multip_li % num_row);
      jQuery(this).addClass('multip'+mod_i);
      multip_li++;
    });      

};

  var filterCategories = function(cat_arr){

    jQuery('#grid').empty();
    jQuery('#all-books').children().clone().appendTo(jQuery('#grid'));
   
    // Check every item
    jQuery('#grid li').each( function(){

      var item_categs_str =  String(jQuery(this).data('categs'));
      var item_categs_arr =  item_categs_str.split(" ");
      var has_categ       = false;

      if (cat_arr.length > 0) {
        console.log('a ');
        // Checking if some categ of that item matches the selected array
        for (var i = item_categs_arr.length - 1; i >= 0; i--) {
          var cat_id  = parseInt(item_categs_arr[i]);
         
          if ( jQuery.inArray( cat_id, cat_arr ) != -1 ){
            has_categ = true;
          }

        }
        
        // In case of no matches, that item is removed from the grid
        if (!has_categ){
          jQuery(this).remove();
        }
      }
      

    });

    reAssignColumnsGrid( jQuery('#grid li') )
    loadBooksGrid();
    
  };



// Animate.css animations for when hovering the elements 
//========================================================================
var animateElementOnHover  = function(e, anim){
  var anim_class = 'animated ' + anim;
  e.hover(
    function(){
      jQuery(this).addClass(anim_class);
    },
    function(){
      jQuery(this).removeClass(anim_class);
    }
  );
};


// AVOID HOVER IN TOUCH DEVICES 
//========================================================================
function is_touch_device() {
  return 'ontouchstart' in window        // works on most browsers 
      || navigator.maxTouchPoints;       // works on IE10/11 and Surface
};

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

        $('body').removeClass('fade-out');

         
      },
      finalize: function() {

       if (is_touch_device()){
        $('body').addClass('touch-device');
       }

        // Back to top ---------------------------------
        $('.backtotop').click(function(){
          $('html, body').animate({
                scrollTop: 0
            },1000);
        });

        // Burger menu ---------------------------------
        $('.mdl-burger').click(function() {


            $('.navbar-header').toggleClass('open');
            $('body').toggleClass('noscroll');
            $('.menu_overlay').toggleClass('overlay');
            $('#mdl_navbar').toggleClass('closed');
            // $('.fixable_scroll').css('top', - $(document).scrollTop());
          
        });

        if (is_touch_device()){
          $('.menu-wrapper').css('height','calc( 100vh - 160px)');

        }
        

        $('.menu_overlay').on('touchmove',function(e){
            
            if( $(this).hasClass('overlay') ){
                e.preventDefault();
                console.log('noscroll');
            }
        });
        
        // Search overlay ---------------------------------
        $('.mdl-search_btn').click(function(){
          $('.mdl-search_overlay').fadeIn();
          if (jQuery(window).width() >= 768){
            $( "#search_book" ).focus();
          }
          
          if ($('body').hasClass('noscroll')){
            $('.mdl-burger').trigger('click');
          }

        });

        $('.close_search').click(function(){
          $('.mdl-search_overlay').fadeOut();

        });



        // Disable browser scrolling on iOS
        // $(document).on('touchmove',function(e) {
        //     console.log($(e.target).attr('class'));
        //     if ($('body').hasClass('noscroll')) {
        //         e.preventDefault();
        //     }
        // });

        // document.body.addEventListener('touchmove',function(e){
        //      if($(e.target).hasClass("noscroll")) {
        //        e.preventDefault();
        //      }
        //  });
        

        
        //========================================================================
        // Apps Section
        //========================================================================
        animateElementOnHover($('.apps-wrapper li'), 'swing');
        $('.apps-item a').click(function(){

          var app_name = $(this).data('appname');
          // console.log(app_name);
          utag.link({
            "event_type":"app_store_click",
            "module_type":"Footer Apps",
            "module_variation": app_name
          });


        });

        //========================================================================
        // NEWSLETTERS
        //========================================================================
        $(".menu-newsletter").submit(function(event) {
            event.preventDefault();
            var form_id     =  $(this).attr('id');      
            _PRH_submitNewsletter( ".footer-newsletter", "Mad Libs Newsletter Menu");

            return false;
        });

        $(".footer-newsletter").submit(function(event) {
            event.preventDefault();
            var form_id     =  $(this).attr('id');      
            _PRH_submitNewsletter( ".footer-newsletter", "Mad Libs Newsletter Footer");

            return false;
        });
        
        //========================================================================
        // SOCIAL FEEDS
        //========================================================================

          
          //----------------------------------------------------------------------
          // Twitter 
          //----------------------------------------------------------------------
          var tweet_car = $("#twitter-carousel");

          tweet_car.owlCarousel({
              responsive : {
                0:{
                    items:1
                },
                768:{
                    items:4
                },
                992:{
                    items:6
                }
              },
              pagination: false
              
          });

          // Custom Navigation Events
          $(".next").click(function(){
            tweet_car.trigger('next.owl.carousel');
          });
          $(".prev").click(function(){
            tweet_car.trigger('prev.owl.carousel');
          });

          tweet_car.on('initialized.owl.carousel changed.owl.carousel refreshed.owl.carousel', function (event) {
                
              if (!event.namespace) return;
              var carousel = event.relatedTarget,
                  element = event.target,
                  current = carousel.current();

                  console.log('')
              $('#twitter .next').toggleClass('disabled', current === carousel.maximum());
              $('#twitter .prev').toggleClass('disabled', current === carousel.minimum());
             
          });





          //----------------------------------------------------------------------
          // Facebook
          //----------------------------------------------------------------------
          var fb_car = $("#facebook-carousel");
          
          // This function helps to crop images and keep the same height among them
          var FacebookSameHeight = function(){ 
            var insta_height = 100000;
            var widthSum  = 0;
            var imgs      = insta_car.find('.item img');

            imgs.each(function(){
              if (this.height < insta_height){
                insta_height = parseInt(this.height);
              }
              
              // console.log(this.height + ' => ' +insta_height);
            });

            fb_car.find('.item').css('height',insta_height+'px');
            fb_car.find('.item').css('overflow','hidden');

          };

          // Trigger the carousel for the images
          fb_car.owlCarousel({
            items : 6, //10 items above 1000px browser width
            responsive : {
              0:{
                  items:2
              },
              768:{
                  items:4
              },
              1100:{
                  items:6
              }
            },
            pagination: false,
            autoPlay: false,
            callbacks:true
            
          
            
            // beforeUpdate: FacebookSameHeight, // Everytime resize, the crop function is called

          });

          // Custom Navigation Events
          $("#facebook .next").click(function(){
            fb_car.trigger('next.owl.carousel');

          });
          $("#facebook .prev").click(function(){
              fb_car.trigger('prev.owl.carousel');
          });


          fb_car.on('initialized.owl.carousel changed.owl.carousel refreshed.owl.carousel', function (event) {
                
              if (!event.namespace) return;
              var carousel = event.relatedTarget,
                  element = event.target,
                  current = carousel.current();

              
              $('#facebook .next').toggleClass('disabled', current === carousel.maximum());
              $('#facebook .prev').toggleClass('disabled', current === carousel.minimum());



              tweet_car.css('height', fb_car.outerHeight() +'px');
          });

          
          tweet_car.css('height', fb_car.outerHeight() +'px');
          // $('#twitter-carousel .item').css('height', fb_car.outerHeight() +'px');
          
          


          // $(window).load( FacebookSameHeight );

      }
    },
    // Home page
    'home': {
      init: function() {

       

      },
      finalize: function() {

        function scrollUpCurtain(){
           // var container = $('body'),
           //    scrollTo = $('.content');

         
           //  // Or you can animate the scrolling:
           //  container.animate({
           //      scrollTop: scrollTo.offset().top - container.offset().top + container.scrollTop()
           //  }, 1000);​
            $('#intro-curtain').css('max-height',0);
            $('#intro-curtain').css('min-height',0);
            $('#intro-curtain-triangle').fadeOut();
        }

        
        $(window).load(function(){

            clearTimeout(resizeId);
            resizeId = setTimeout(function(){
                setTilesSize();
                $('.main').css('opacity',1);
                $('#section-apps').css('opacity',1);
                $('#social-feeds').css('opacity',1);
            }, 1000);

            
            // LANDING PAGE COOKIES ===================================================
            // ========================================================================
            
              var popup_var = true;
              if (popup_var){
                
                var thirdtyMinutesLater = new Date();
                thirdtyMinutesLater.setMinutes(thirdtyMinutesLater.getMinutes() + 30);
                  
                  if ($.cookie('landing_shown') == null) {
                    $.cookie('landing_shown', 'yes', { expires: thirdtyMinutesLater, path: '/' });
                  }
                 
              }
        });



        // TILES   =========================================================
        // ========================================================================
        var resizeId;
        $('.tile').click(function(){
          var tile_pos = $(this).data('pos');
          console.log(tile_pos);

          utag.link({
            'module_type': 'Show My Books Clicks',
            'module_variation': tile_pos
            });

        });



        $('.tile-cover').click(function(){
          
            $(this).parent().closest('.tile').addClass('active');
            $(this).fadeOut(500, function(){
              $(this).siblings('.tile-info').fadeIn(1500);  
            });
            
         
        });

        $('.tile-close').click(function(){
          
          $(this).parent().closest('.tile').removeClass('active');
          $(this).siblings('.tile-info').hide(0, function(){
            $(this).siblings('.tile-cover').fadeIn(1500);  
          });
          
        });

        animateElementOnHover($('.tile-icons li a'), 'jello');
        $('.tile-icons a').click(function(){
          var social_platform = $(this).data('socialplatform');
          // console.log(social_platform);
          utag.link({
            "event_type":"product_share",
            "social_platform" : social_platform,
            "share_type": "homepage_tile"
          });

        });



        var resizeId;
        $( window ).on( 'resize', function(){
            clearTimeout(resizeId);
            resizeId = setTimeout(function(){
                setTilesSize();
            }, 500);

        });




        


           
    
          
        // ========================================================================
        // FEATURED BOOKS  ========================================================
        // ========================================================================
        animateElementOnHover($('#featured-books-carousel .item'), 'pulse');
        
        var f_books = $("#featured-books-carousel");

        f_books.owlCarousel({
            responsive : {
              0:{
                  items:2
              },
              768:{
                  items:6
              }
            },
            pagination: false
            
        });

        // Custom Navigation Events
        $(".next").click(function(){
          f_books.trigger('next.owl.carousel');
        });
        $(".prev").click(function(){
          f_books.trigger('prev.owl.carousel');
        });


        // ========================================================================
        // INTRO CURTAIN  =========================================================
        // ========================================================================
        
        var adjustScroll  = true,
            firstScroll   = true;   
        
        

        $(window).bind("load", function() {
            

              if (adjustScroll) {
                  console.log('scrolling');
                  $("html,body").scrollTop(0);
                  adjustScroll = false;
                  firstScroll  = false;
              }

              

        });

        $('#intro-curtain-triangle').click(function(){
          scrollUpCurtain();
        });
        var rolledUp = true;
        $(window).on("scroll", function() {
          var scrollTop = $(window).scrollTop();
          if (scrollTop > 1 && rolledUp) {
             
              $("html,body").css('overflow','hidden');
              $("html,body").scrollTop(0);
              scrollUpCurtain();
              
              rolledUp = false;
              
              
              $.fn.scrollEnd = function(callback, timeout) {          
                  $(this).scroll(function(){
                    var $this = $(this);
                    if ($this.data('scrollTimeout')) {
                      clearTimeout($this.data('scrollTimeout'));
                    }
                    $this.data('scrollTimeout', setTimeout(callback,timeout));
                  });
                };

              // how to call it (with a 1000ms timeout):
              $(window).scrollEnd(function(){
                  $("html,body").css('overflow','');
              }, 2000);

         
              
          }
          
      });

        

        // $(window).on("scroll", function(){
        //   console.log(adjustScroll);
        //   if (!adjustScroll) {

        //     $('#intro-curtain-triangle').trigger('click');
        //   }
          
        // });​

      

    }
  },
    // About us page, note the change from about-us to about_us.
    'post_type_archive_printable': {
      init: function() {
        

        var resizeId;
        
        $(window).load(  function(){
            clearTimeout(resizeId);
            resizeId = setTimeout(function(){
              reAssignColumnsGridPrintables( $('#grid li'));
            }, 500);


        });

        
        if (!is_touch_device()){
          var windowWidth = $(window).width(); // Bug on ipad scroll triggers the resize
          $( window ).on( 'resize', function(){
              if ($(window).width() != windowWidth) {
                $('#grid li').removeAttr('class');
                $('#grid li').removeAttr('style');
                $('.printable-collection').css('height', 0);

                clearTimeout(resizeId);
                resizeId = setTimeout(function(){
                  reAssignColumnsGridPrintables( $('#grid li'));          
                    // loadBooksGrid();
                }, 500);
              }
          });
        }
        else{
          $( window ).on( 'orientationchange', function(){
                $('#grid li').removeAttr('class');
                $('#grid li').removeAttr('style');
                $('.printable-collection').css('height', 0);

                clearTimeout(resizeId);
                resizeId = setTimeout(function(){
                  reAssignColumnsGridPrintables( $('#grid li'));          
                    // loadBooksGrid();
                }, 500);
          });
        }


      }
    },


    // ========================================================================
    // BOOKS  =================================================================
    // ========================================================================
    'single_book': {
      finalize: function() {
        
        
        //--------------------------------------------------------------------
        // SOCIAL SHARE   ----------------------------------------------------
        //--------------------------------------------------------------------
        animateElementOnHover($('.pdp_socialshare .item'), 'jello');
        
        $('.pdp_socialshare .item').click(function(){
          var social_platform = $(this).data('socialplatform');
          var isbn            = $(this).data('isbn');
          var title           = $(this).data('title');
          // var cover           = $(this).data('cover');
          // console.log(isbn);
        

          utag.link({
            "event_type":"product_share",
            "social_platform" : social_platform,
            "share_type ":"book",
            "product_title": title,
            "product_isbn": isbn
          });


          // Remove the metas
          // $('meta[property="og:image:secure_url"]').attr('content', cover);

        });
        
        //--------------------------------------------------------------------
        // RETAILERS  --------------------------------------------------------
        //--------------------------------------------------------------------
        $('.retailers-list a').click(function(){

          var isbn      = $(this).data('isbn');
          var title     = $(this).data('title');
          var retailer  = $(this).data('retailer');

          utag.link({
            "event_type":"affiliate_click",
            "affiliate_name": retailer,
            "product_author" : "",
            "product_title": title,
            "product_isbn": isbn,
            "product_format":""
          });

        });

        // Close retailers ovelay ----------------
        $('.retailers-close').click(function(){
          var target = $(this).data('target');
          $('#mdl-retailers-desk-' + target).fadeOut();
          $('#mdl-retailers-xs-' + target).fadeOut();
          $('body').removeClass('noscroll');

          $.fn.fullpage.setMouseWheelScrolling(true);
          $.fn.fullpage.setAllowScrolling(true);
        });

        // Open retailers ovelay -----------------
        $('.mdl-btn').click(function(){
          var target = $(this).data('target');
          $('#mdl-retailers-desk-' + target).fadeIn();
          $('body').toggleClass('noscroll');

          $.fn.fullpage.setMouseWheelScrolling(false);
          $.fn.fullpage.setAllowScrolling(false);
         
        });

        $('.mdl-btn-xs').click(function(){
          var target = $(this).data('target');
          $('#mdl-retailers-xs-' + target).fadeIn();
          $('body').toggleClass('noscroll');
         
        });

        // Close Read More ovelay ----------------
        $('.readmore-close').click(function(){
          var target = $(this).data('target');
          $('#mdl-readmore-' + target).fadeOut();
          $('body').removeClass('noscroll');

          $.fn.fullpage.setMouseWheelScrolling(true);
          $.fn.fullpage.setAllowScrolling(true);
        });

        // Open Read More overlay ------------------
        $('.readmore-btn').click(function(){
          var target = $(this).data('target');
          $('#mdl-readmore-' + target).fadeIn();
          $('body').toggleClass('noscroll');
          
          $.fn.fullpage.setMouseWheelScrolling(false);
          $.fn.fullpage.setAllowScrolling(false);
         
        });

        
        var refreshSlideHeight = function(slide){
          var slide_h     = slide.css('height');
          $('#pdp_right .pdp_slide').each(function(){
              $(this).css('height', slide_h);
          });
        };

        $('#pdp_left').fullpage({
          sectionSelector: '#pdp_left .pdp_slide',
          fitToSection: true,
          responsive: 768,
          touchSensitivity: 5,
          css3: true,
          afterResize: function(){
            refreshSlideHeight($(this));
            alignPDPElemTop();
            
          },
          afterRender: function(){
            //console.log($('.pdp_cover').first().position().top );
            refreshSlideHeight($(this));
            alignPDPElemTop();
       
          },

          onLeave: function(index, nextIndex, direction){
              //console.log($('.pdp_cover').first().position().top );
              var slide_height  = parseFloat($('#pdp_right .pdp_slide').css('height'));
              
              console.log('next'+nextIndex);
              

              var bottom_pos    = - slide_height * parseFloat(nextIndex - 1);

              // console.log('altura:'+slide_height);
              $('#pdp_right').css('bottom', bottom_pos+'px');

              var amount_slides = $('#pdp_right .hidden-xs .pdp_slide').length;
              
              // console.log(nextIndex);

              if (nextIndex == amount_slides){                
                $('.pdp_nav #prev').addClass('active');
              }
              else{
                $('.pdp_nav #prev').removeClass('active');
              }

              if (nextIndex == 1){                
                $('.pdp_nav #next').addClass('active');
              }
              else{
                $('.pdp_nav #next').removeClass('active');
              }

          }
        });

        // $.fn.fullpage.setMouseWheelScrolling(false);
        //$.fn.fullpage.setAllowScrolling(false);

        $('.pdp_nav #next').click(function(){
            $.fn.fullpage.moveSectionUp();

        });
        $('.pdp_nav #prev').click(function(){
            $.fn.fullpage.moveSectionDown();
        });


      }
    },

    'history':{
      finalize: function(){
        


        var top_affix = 0;
        var lastScrollTop = 0;

        
        // $(".affix .history_scroller").bind( 'mousewheel DOMMouseScroll', function ( e ) {
        //     var e0 = e.originalEvent,
        //         delta = e0.wheelDelta || -e0.detail;

        //     this.scrollTop += ( delta < 0 ? 1 : -1 ) * 30;
        //     e.preventDefault();
        // });

        $(window).on("scroll", function(e){
           e.preventDefault();
          
          // $('.affix').css('top', top_affix);
        });
        
        var resizeId;

        $(window).on("load resize", function(){
          
          //window.scrollTo(0,0);
         
          clearTimeout(resizeId);
          resizeId = setTimeout(function(){
            

            if (!is_touch_device()){
              window.scrollTo(0,0);    
              // HistorySectionsSameHeight();

              var top_pos, top_gap;

              top_pos =  $('#hist1').offset().top;
              
              // Set the affix for 1 ====================                
              //=========================================
              $('#hist1').affix({
                  offset: {
                    top: top_pos - 55
                  }
              });

              // Set the affix for 2 to 5 ===============
              //=========================================
              for (var i = 2; i <= 5; i++) {
                  top_pos =  $('#hist'+i).offset().top;
                  top_gap = 0;//55*2;
                  console.log('top_pos - top_gap ' + top_pos + ' - ' + top_gap);
                  
                  $('#hist'+i).affix({
                    offset: {
                      top: top_pos - top_gap
                    }
                  });

              };


           

              $('.history_milestone').on('affix.bs.affix', function(){ 

                  $('.history_milestone').removeClass('affix');
                  
                  $('.ribbon-item[data-id="'+ $(this).attr('id') +'"]').hide();

                  $(this).css('margin-top', 0 );

                  if ( $(this).attr('id') != 'hist1'){
                    
                    $(this).css('top', 55 );
                  }
                  else{
                    $(this).css('margin-top', 55 );
                    $(this).css('top', 0 );
                  }

                  // if ( $(this).attr('id') != 'hist5'){

                    $(this).next().css('margin-top',$(this).outerHeight(true) - 55 );
                    $(this).next().css('z-index', parseInt($(this).css('z-index')) + 1);
                  // }


              });


              $('.history_milestone').on( 'affix-top.bs.affix', function () {
                  $(this).css('margin-top', 0 );
                  $('.ribbon-item[data-id="'+ $(this).attr('id') +'"]').show();
                
                  if ( $(this).attr('id') != 'hist1'){
                    $(this).css('margin-top', 0 );
                    $(this).css('top', 0 );
                  }
                  else{
                    $(this).css('margin-top', 0 );
                    $(this).css('top', 0 );
                  }
              } );

            }
            else{
              $('.history-ribbons').hide();
            }


          }, 500);

        });

        

        


      }
    },
    'search_results':{
      finalize: function(){
          
          $(window).load(  function(){
            reAssignColumnsGrid( $('#grid li'));
            loadBooksGrid();
          });

          var resizeId;
          $( window ).on( 'resize', function(){
            clearTimeout(resizeId);
            resizeId = setTimeout(function(){
                reAssignColumnsGrid( $('#grid li'));          
                loadBooksGrid();
            }, 500);

          });

      }  
    },
    'post_type_archive_book':{
      finalize: function(){
        
        // --------------------------------------------------------------------
        // Categories Carousel ------------------------------------------------
        // --------------------------------------------------------------------

        var CategoryFilterRoller  = function(){
          var widthSum = 0;
          var categ_roller_padding = 0;
          var cur_width = 0;
         

          $(".category-filter .categ-item").each(function(){
            
            cur_width = $(this).outerWidth(true);
            
            widthSum += cur_width;
            console.log( cur_width);

          });
    

          $('.category-filter').css('width', widthSum+5 );
          $('.category-filter').css('overflow','visible');
        };

        animateElementOnHover($('#grid img'), 'pulse');

        $(window).load(  function(){
            var resizeId;
            clearTimeout(resizeId);
            resizeId = setTimeout(function(){
              CategoryFilterRoller();
            });

            reAssignColumnsGrid( $('#grid li'));
            loadBooksGrid();
            


            if($(document).scrollTop() > 100){
              
              if ($(document).scrollTop() > ($('#section-apps').offset().top - 220)){
                $(".page-header .title").slideDown(200);
              }
              else{
                $(".page-header .title").slideUp(200);
              }
            }
            else{
              $(".page-header .title").slideDown(200);
            }

            

        
            // Get Pre-selected categories (URL) =================
            
            if ($('#selected_categ').val() != ''){
              var categ_preloaded = parseInt( $('#selected_categ').val() );
             
              categs_selected.push(categ_preloaded);
              filterCategories( categs_selected );
              $('*[data-categ="'+categ_preloaded+'"]').toggleClass('active');
            }

          }
        );

        // --------------------------------------------------------------------
        // Scrolling and shrink books header ----------------------------------
        // --------------------------------------------------------------------
        var scrollTimeout;
        $(window).on("scroll", function(){
          // if (scrollTimeout) {
          //   clearTimeout(scrollTimeout);
          //   scrollTimeout = null;
          // }

          // scrollTimeout = setTimeout( function(){
          if ( $(document).scrollTop() > 100  ){
            if ($(document).scrollTop() > ($('#section-apps').offset().top - 220) ){
                $(".page-header .categ-wrapper").slideUp(200);
              }
              else{
                $(".page-header .title").slideUp(200);
                $(".page-header .categ-wrapper").slideDown(200);
                CategoryFilterRoller();
              }
          }
          else{
            $(".page-header .title").slideDown(200);
            CategoryFilterRoller();
        
          }
          // }, 150);


        });
    

        
        

        
        $( window ).on( 'resize', function(){


          var resizeId;
          clearTimeout(resizeId);
          resizeId = setTimeout(function(){
              CategoryFilterRoller();
              reAssignColumnsGrid( $('#grid li'));          
              loadBooksGrid();
          }, 500);

        });
       
        // --------------------------------------------------------------------
        // Categories filter
        // --------------------------------------------------------------------
        var categs_selected = [];
        
       
        $('.categ-item').click(function(){          
          var categ_id = $(this).data('categ');
          
          if ( $.inArray( categ_id, categs_selected ) == -1 ){
           
            categs_selected.push(categ_id);
          }
          else{
            var i = categs_selected.indexOf(categ_id);
            categs_selected.splice(i,1); 
          }

          filterCategories( categs_selected );
          $(this).toggleClass('active');

          // Scroll back to top 
          $('html, body').animate({ scrollTop: 0 },1000);
        });

        // --------------------------------------------------------------------
        // Clear categs
        // --------------------------------------------------------------------
        $('.categ-clear').click(function(){
          $('.categ-item').removeClass('active');
          categs_selected = [];
          filterCategories( categs_selected );
          

          // Scroll back to top 
          $('html, body').animate({ scrollTop: 0 },1000);

        });

      }
    }

  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
